<template>
  <div class="root">
    <div class="head" v-if="tablas[tabla].datos">
      <button @click="descargar">Descargar</button>
      <button @click="nuevo">Nuevo</button>
      <button @click="quitarfiltros">Filtros</button>
      <select v-model="tablas[tabla].registros">
        <option>50</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
      </select>
      <span>{{tablas[tabla].datos.length}}/{{tablas[tabla].longitud}}</span>
    </div>
    <div class="container"> 
      <Tabla @link="link" @filtrar="e=>filtrar([e,tabla])" @ordenar="e=>ordenar([e,tabla])" v-if="tablas[tabla].campos && tablas[tabla].campos.length>0" @scroll="scroll" @evento="e=>cambiospreferencias([e,tabla])" ref="tabla" :key="key" :datos="tablas[tabla].datos" :campos="tablas[tabla].campos" :filtrable="true"/>
    </div>
    <Modal @salir="modal=false" v-if="modal"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Tabla from '@/components/Tabla.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: 'Presupuestos',
  data: function (){
    return{
      tabla: 'presupuestos',
      key: 1,
      modal: false,
      algo: null
    }
  },
  components: {
    Tabla,
    Modal
  },
  computed: {
    ...mapState(['tablas','cache'])
  },
  methods: {
    ...mapActions(['api','pedir','filtrar','ordenar','get_campos','cambiospreferencias','ver_mas','exportCSVFile']),
    async scroll(){
      let con = document.getElementById('container')
      if(con.scrollTop+con.clientHeight===con.scrollHeight){
        await this.ver_mas(this.tabla)
      }
    },
    descargar(){
      this.exportCSVFile([null,this.tablas[this.tabla].datos,this.tabla,'descargar'])
    },
    async link(e){
      let a = await this.api({
        archivo: 'madre',
        tipo: 'select',
        campos: ['*'],
        tabla: this.tabla.toUpperCase(),
        filtros: {
          ID: "='"+e.id+"'"
        },
        limite: 1,
        orden: null,
        respond: 'true',
        offset: 0
      })
      e.datos = a[0]
      this.cache.ventanas.push(e)
      this.modal = true
    },
    async nuevo(){
      let a = document.getElementById('tabla')
      this.resizableGrid(a)
      // await this.api() // Aqui faltan muchos parametros
      // this.key++
    },
    quitarfiltros(){
      this.$refs.tabla.quitarfiltros()
    },
    resizableGrid() {
      let table = document.getElementById('tabla')
      var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
      if (!cols) return;
      
      table.style.overflowX = 'hidden';
      
      var tableHeight = table.offsetHeight;
      
      for (var i=0;i<cols.length;i++){
        var div = createDiv(tableHeight);
        cols[i].appendChild(div);
        setListeners(div);
      }

      function setListeners(div){
        var pageX,curCol,nxtCol,curColWidth,nxtColWidth;

        div.addEventListener('mousedown', function (e) {
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX; 
      
        var padding = paddingDiff(curCol);
      
        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol)
          nxtColWidth = nxtCol.offsetWidth - padding;
        });

        div.addEventListener('mouseover', function (e) {
        e.target.style.borderRight = '2px solid #0000ff';
        })

        div.addEventListener('mouseout', function (e) {
        e.target.style.borderRight = '';
        })

        document.addEventListener('mousemove', function (e) {
        if (curCol) {
          var diffX = e.pageX - pageX;
          curCol.style.width = (curColWidth + diffX)+'px';
        }
        });

        document.addEventListener('mouseup', function (e) { 
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined
        });
      }

      function createDiv(height){
        var div = document.createElement('div');
        div.style.top = 0;
        div.style.right = 0;
        div.style.width = '5px';
        div.style.position = 'absolute';
        div.style.cursor = 'col-resize';
        div.style.userSelect = 'none';
        div.style.height = height + 'px';
        return div;
      }

      function paddingDiff(col){
      
        if (getStyleVal(col,'box-sizing') == 'border-box'){
        return 0;
        }
      
        var padLeft = getStyleVal(col,'padding-left');
        var padRight = getStyleVal(col,'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));

      }

      function getStyleVal(elm,css){
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
      }
    }
  },
  async beforeMount(){
    let b = this
    this.algo = window.setInterval(function (){
      let a = document.getElementsByTagName('table')
      if(a.length>0){
        b.resizableGrid(a[0])
        clearInterval(b.algo)
      }
    },100)
    if(this.tablas[this.tabla].campos){
    }else{
      await this.get_campos(this.tabla)  
    }
    if(this.tablas[this.tabla].datos.length<=0){
      await this.pedir([0,null,this.tabla])
    }
  }
}
</script>

<style scoped>
.head{
  flex-grow: 0;
  color: white;
  width: 100%;
  text-align: right;
  background-color: rgb(255, 172, 116);
}
.container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.container > div{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.root{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>